<template>
  <div class="pt-5">
    <div
      v-if="loading"
      class="text-center align-self-end m-5"
      style="
        height: 200px;

        align-content: end;
      "
    >
      <b-spinner
        label="Loading ..."
        style="width: 3rem; height: 3rem"
        type="grow"
      ></b-spinner>
    </div>
    <section
      v-else
      id="home"
      :style="getImage"
      class="iq-main-slider main_bg p-0"
    >
      <div class="slick-bg">
        <b-container class="position-relative h-100" fluid>
          <div class="slider-inner h-100">
            <b-row class="align-items-center h-100 justify-content-center pt-5">
              <b-col
                class="text-center d-flex flex-column justify-content-center align-items-center"
                lg="12"
                md="12"
                xl="6"
              >
                <h1
                  class="slider-text text-center big-title title text-uppercase"
                  data-animation-in="fadeInLeft"
                  data-delay-in="0.6"
                  style="font-family: MyriadPro-Bold; text-align: start"
                >
                  {{ data.title?.[$i18n.locale] }}
                </h1>
                <p
                  class="text-center"
                  data-animation-in="fadeInUp"
                  data-delay-in="1.2"
                  style="width: 18rem; font-size: 1.2rem"
                >
                  {{ data.description?.[$i18n.locale] }}
                </p>
                <div
                  class="d-flex align-items-center r-mb-23 text-center justify-content-center text-white"
                  data-animation-in="fadeInUp"
                  data-delay-in="1.2"
                >
                  <a
                    class="btn btn-hover viewMoreBtn text-center d-block"
                    @click="scrollToDetail"
                  >
                    <div v-if="$i18n.locale == 'en'">View More</div>
                    <div v-if="$i18n.locale == 'ku'">زیاتر نيشان بده</div>
                    <div v-if="$i18n.locale == 'ar'">عرض المزيد</div>
                  </a>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-container>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.justified-text {
  direction: rtl;
  text-align: justify;
}

.main_bg {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.main_bg {
  @media (min-width: 769px) {
    height: 100vh !important;
  }
}

.slick-bg {
  background-size: 100% 100% !important;

  @media (max-width: 768px) {
    background-size: cover;
  }

  @media (min-width: 769px) {
    height: 100vh !important;
    background-size: cover;
  }
}
</style>
<script>
import ApiService from "../../../../services/api"
import $ from "jquery"

export default {
  name: "TheHome",
  components: {},
  mounted() {
    ApiService.getSetting(this.$route.params.categoryId)
      .then(response => {
        this.data = response.data.data
      })
      .finally(() => {
        this.loading = false
      })
  },
  data() {
    return {
      loading: true,
      slideData: {
        title: "Entertainia",
        age: "16",
        sesson: "2h 40m",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        src: require("@/assets/images/frontend/slider/slider2.jpg")
      },
      data: {}
    }
  },
  methods: {
    scrollToDetail() {
      const detailElement = document.querySelector("#viewMore")
      if (detailElement) {
        $("html, body").animate(
          {
            scrollTop: window.innerHeight
          },
          "300"
        )
      }
    },
    getImageBackgroundByresponse() {
      return {
        background: this.data.mob_img
          ? `url(${this.data.mob_img})`
          : `url( require("../../../../assets/background3.png"))`,
        padding: "100px 0 50px"
      }
    }
  },
  computed: {
    getImage() {
      return {
        ...this.getImageBackgroundByresponse()
      }
    }
  }
}
</script>
